@font-face {
  font-family: 'NotoSans';
  src: local('NotoSans'), url(./fonts/NotoSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'NotoSans-Italic';
  src: local('NotoSans-Italic'), url(./fonts/NotoSans-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'NotoSans-Light';
  src: local('NotoSans-Light'), url(./fonts/NotoSans-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'NotoSans-Medium';
  src: local('NotoSans-Medium'), url(./fonts/NotoSans-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'NotoSans-SemiBold';
  src: local('NotoSans-SemiBold'), url(./fonts/NotoSans-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'NotoSans-Bold';
  src: local('NotoSans-Bold'), url(./fonts/NotoSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'DMSans';
  src: local('DMSans'), url(./fonts/DMSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'DMSans-MediumItalic';
  src: local('DMSans-MediumItalic'), url(./fonts/DMSans-MediumItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'DMSans-Medium';
  src: local('DMSans-Medium'), url(./fonts/DMSans-Medium.ttf) format('truetype');
}


@font-face {
  font-family: 'DMSans-Italic';
  src: local('DMSans-Italic'), url(./fonts/DMSans-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'DMSans-BoldItalic';
  src: local('DMSans-BoldItalic'), url(./fonts/DMSans-BoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'DMSans-Bold';
  src: local('DMSans-Bold'), url(./fonts/DMSans-Bold.ttf) format('truetype');
}

/* used for supporting document extension text */
@font-face {
  font-family: 'NotoMono-Regular';
  src: local('NotoMono-Regular'), url(./fonts/NotoMono-Regular.ttf) format('truetype');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}